/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from 'prop-types';
import { useMemo } from 'react';
// @mui
import { CssBaseline } from '@mui/material';
import { ThemeProvider as MUIThemeProvider, createTheme, StyledEngineProvider } from '@mui/material/styles';
//
import palette from './palette';
import paletteDark from './palette-dark';

import shadows from './shadows';
import typography from './typography';
import GlobalStyles from './globalStyles';
import customShadows from './customShadows';
import componentsOverride from './overrides';
import componentsDarkOverride from './overrides-dark';

// ----------------------------------------------------------------------

ThemeProvider.propTypes = {
  children: PropTypes.node,
  user: PropTypes.object,
};

export default function ThemeProvider({ user, children }) {
  const student = user?.role === 'student';

  const themeMode = student ? 'dark' : 'light';
  const themeOptions = useMemo(
    () => ({
      palette: themeMode === 'light' ? palette : paletteDark,
      shape: { borderRadius: student ? 10 : 6 },
      typography,
      shadows: shadows(),
      customShadows: customShadows(),
      breakpoints:
        themeMode === 'light'
          ? {}
          : {
              values: {
                xs: 0,
                sm: 600,
                md: 960,
                lg: 1280,
                xl: 1536,
              },
            },
    }),
    []
  );

  const theme = createTheme(themeOptions);
  theme.components = student ? componentsDarkOverride(theme) : componentsOverride(theme);

  return (
    <StyledEngineProvider injectFirst>
      <MUIThemeProvider theme={theme}>
        <CssBaseline />
        <GlobalStyles />
        {children}
      </MUIThemeProvider>
    </StyledEngineProvider>
  );
}
