import { alpha } from '@mui/material/styles';

// ----------------------------------------------------------------------

// SETUP COLORS
const GREY = {
  0: '#FFFFFF',
  50: '#FCFDFD',
  100: '#F9FAFB',
  200: '#F4F6F8',
  300: '#DFE3E8',
  400: '#C4CDD5',
  500: '#919EAB',
  600: '#637381',
  700: '#454F5B',
  800: '#1C252E',
  900: '#141A21',
  1000: '#0d1415',
};

const PRIMARY = {
  main: '#D2691E', // Chocolate
  light: '#E9967A', // Dark Salmon
  dark: '#8B4513', // Saddle Brown
  lighter: '#F4A460', // Sandy Brown
  darker: '#A0522D', // Sienna
  contrastText: '#FFFFFF', // White
};

const SECONDARY = {
  main: '#505050', // Dark gray
  light: '#757575',
  dark: '#2C2C2C',
  lighter: '#BDBDBD',
  darker: '#1C1C1C',
  contrastText: '#FFFFFF',
};
const INFO = {
  main: '#4682B4', // Steel Blue
  light: '#87CEEB', // Sky Blue
  dark: '#2F4F4F', // Dark Slate Gray
  lighter: '#B0C4DE', // Light Steel Blue
  darker: '#1E3A5F', // Darker Steel Blue
  contrastText: '#FFFFFF', // White
};

const SUCCESS = {
  main: '#556B2F', // Dark Olive Green
  light: '#6B8E23', // Olive Drab
  dark: '#2E8B57', // Sea Green
  lighter: '#8FBC8F', // Dark Sea Green
  darker: '#3B5323', // Darker Olive Green
  contrastText: '#FFFFFF', // White
};

const WARNING = {
  main: '#DAA520', // Goldenrod
  light: '#FFD700', // Gold
  dark: '#B8860B', // Dark Goldenrod
  lighter: '#FFE4B5', // Moccasin
  darker: '#8B6508', // Darker Goldenrod
  contrastText: '#000000', // Black
};
const ERROR = {
  main: '#CD5C5C', // Indian Red
  light: '#F08080', // Light Coral
  dark: '#8B0000', // Dark Red
  lighter: '#FA8072', // Salmon
  darker: '#800000', // Maroon
  contrastText: '#FFFFFF', // White
};
const VIOLET = {
  main: '#6A5ACD', // Slate Blue
  light: '#9370DB', // Medium Purple
  dark: '#4B0082', // Indigo
  lighter: '#D8BFD8', // Thistle
  darker: '#2E0854', // Darker Indigo
  contrastText: '#FFFFFF', // White
};
const palette = {
  common: { black: '#000', white: '#fff' },
  primary: PRIMARY,
  secondary: SECONDARY,
  info: INFO,
  success: SUCCESS,
  warning: WARNING,
  error: ERROR,
  grey: GREY,
  violet: VIOLET,
  divider: alpha(GREY[500], 0.24),
  text: {
    primary: '#FFFFFF',
    secondary: '#D4D4D2',
    disabled: GREY[600],
  },
  background: {
    default: '#1C1C1C',
    paper: '#2C2C2C',
    neutral: '#F5F5F5',
  },
  action: {
    active: GREY[600],
    hover: alpha(GREY[500], 0.08),
    selected: alpha(GREY[500], 0.16),
    disabled: alpha(GREY[500], 0.8),
    disabledBackground: alpha(GREY[500], 0.24),
    focus: alpha(GREY[500], 0.24),
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
};

export default palette;

// --palette-primary-lighter: #CDE9FD;
// --palette-primary-light: #6BB1F8;
// --palette-primary-main: #0C68E9;
// --palette-primary-dark: #063BA7;
// --palette-primary-darker: #021D6F;
// --palette-primary-contrastText: #FFFFFF;
// --palette-primary-lighterChannel: 205 233 253;
// --palette-primary-lightChannel: 107 177 248;
// --palette-primary-mainChannel: 12 104 233;
// --palette-primary-darkChannel: 6 59 167;
// --palette-primary-darkerChannel: 2 29 111;
// --palette-primary-contrastTextChannel: 255 255 255;
// --palette-secondary-lighter: #EFD6FF;
// --palette-secondary-light: #C684FF;
// --palette-secondary-main: #8E33FF;
// --palette-secondary-dark: #5119B7;
// --palette-secondary-darker: #27097A;
// --palette-secondary-contrastText: #FFFFFF;
// --palette-secondary-lighterChannel: 239 214 255;
// --palette-secondary-lightChannel: 198 132 255;
// --palette-secondary-mainChannel: 142 51 255;
// --palette-secondary-darkChannel: 81 25 183;
// --palette-secondary-darkerChannel: 39 9 122;
// --palette-secondary-contrastTextChannel: 255 255 255;
// --palette-info-lighter: #CAFDF5;
// --palette-info-light: #61F3F3;
// --palette-info-main: #00B8D9;
// --palette-info-dark: #006C9C;
// --palette-info-darker: #003768;
// --palette-info-contrastText: #FFFFFF;
// --palette-info-lighterChannel: 202 253 245;
// --palette-info-lightChannel: 97 243 243;
// --palette-info-mainChannel: 0 184 217;
// --palette-info-darkChannel: 0 108 156;
// --palette-info-darkerChannel: 0 55 104;
// --palette-info-contrastTextChannel: 255 255 255;
// --palette-success-lighter: #D3FCD2;
// --palette-success-light: #77ED8B;
// --palette-success-main: #22C55E;
// --palette-success-dark: #118D57;
// --palette-success-darker: #065E49;
// --palette-success-contrastText: #ffffff;
// --palette-success-lighterChannel: 211 252 210;
// --palette-success-lightChannel: 119 237 139;
// --palette-success-mainChannel: 34 197 94;
// --palette-success-darkChannel: 17 141 87;
// --palette-success-darkerChannel: 6 94 73;
// --palette-success-contrastTextChannel: 255 255 255;
// --palette-warning-lighter: #FFF5CC;
// --palette-warning-light: #FFD666;
// --palette-warning-main: #FFAB00;
// --palette-warning-dark: #B76E00;
// --palette-warning-darker: #7A4100;
// --palette-warning-contrastText: #1C252E;
// --palette-warning-lighterChannel: 255 245 204;
// --palette-warning-lightChannel: 255 214 102;
// --palette-warning-mainChannel: 255 171 0;
// --palette-warning-darkChannel: 183 110 0;
// --palette-warning-darkerChannel: 122 65 0;
// --palette-warning-contrastTextChannel: 28 37 46;
// --palette-error-lighter: #FFE9D5;
// --palette-error-light: #FFAC82;
// --palette-error-main: #FF5630;
// --palette-error-dark: #B71D18;
// --palette-error-darker: #7A0916;
// --palette-error-contrastText: #FFFFFF;
// --palette-error-lighterChannel: 255 233 213;
// --palette-error-lightChannel: 255 172 130;
// --palette-error-mainChannel: 255 86 48;
// --palette-error-darkChannel: 183 29 24;
// --palette-error-darkerChannel: 122 9 22;
// --palette-error-contrastTextChannel: 255 255 255;
// --palette-grey-50: #FCFDFD;
// --palette-grey-100: #F9FAFB;
// --palette-grey-200: #F4F6F8;
// --palette-grey-300: #DFE3E8;
// --palette-grey-400: #C4CDD5;
// --palette-grey-500: #919EAB;
// --palette-grey-600: #637381;
// --palette-grey-700: #454F5B;
// --palette-grey-800: #1C252E;
// --palette-grey-900: #141A21;
// --palette-grey-50Channel: 252 253 253;
// --palette-grey-100Channel: 249 250 251;
// --palette-grey-200Channel: 244 246 248;
// --palette-grey-300Channel: 223 227 232;
// --palette-grey-400Channel: 196 205 213;
// --palette-grey-500Channel: 145 158 171;
// --palette-grey-600Channel: 99 115 129;
// --palette-grey-700Channel: 69 79 91;
// --palette-grey-800Channel: 28 37 46;
// --palette-grey-900Channel: 20 26 33;
// --palette-common-black: #000000;
// --palette-common-white: #FFFFFF;
// --palette-common-blackChannel: 0 0 0;
// --palette-common-whiteChannel: 255 255 255;
// --palette-common-background: #000;
// --palette-common-onBackground: #fff;
// --palette-common-backgroundChannel: 0 0 0;
// --palette-common-onBackgroundChannel: 255 255 255;
// --palette-divider: rgba(145 158 171 / 0.2);
// --palette-action-hover: rgba(145 158 171 / 0.08);
// --palette-action-selected: rgba(145 158 171 / 0.16);
// --palette-action-focus: rgba(145 158 171 / 0.24);
// --palette-action-disabled: rgba(145 158 171 / 0.8);
// --palette-action-disabledBackground: rgba(145 158 171 / 0.24);
// --palette-action-hoverOpacity: 0.08;
// --palette-action-disabledOpacity: 0.48;
// --palette-action-active: #919EAB;
// --palette-action-selectedOpacity: 0.16;
// --palette-action-focusOpacity: 0.12;
// --palette-action-activatedOpacity: 0.24;
// --palette-action-activeChannel: 145 158 171;
// --palette-action-selectedChannel: 145;
// --palette-text-primary: #FFFFFF;
// --palette-text-secondary: #919EAB;
// --palette-text-disabled: #637381;
// --palette-text-primaryChannel: 255 255 255;
// --palette-text-secondaryChannel: 145 158 171;
// --palette-text-disabledChannel: 99 115 129;
// --palette-background-paper: #1C252E;
// --palette-background-default: #141A21;
// --palette-background-neutral: #28323D;
// --palette-background-paperChannel: 28 37 46;
// --palette-background-defaultChannel: 20 26 33;
// --palette-background-neutralChannel: 40 50 61;
// --palette-Alert-errorColor: rgb(255, 221, 205);
// --palette-Alert-infoColor: rgb(191, 250, 250);
// --palette-Alert-successColor: rgb(200, 247, 208);
// --palette-Alert-warningColor: rgb(255, 238, 193);
// --palette-Alert-errorFilledBg: var(--palette-error-dark, #B71D18);
// --palette-Alert-infoFilledBg: var(--palette-info-dark, #006C9C);
// --palette-Alert-successFilledBg: var(--palette-success-dark, #118D57);
// --palette-Alert-warningFilledBg: var(--palette-warning-dark, #B76E00);
// --palette-Alert-errorFilledColor: #fff;
// --palette-Alert-infoFilledColor: #fff;
// --palette-Alert-successFilledColor: #fff;
// --palette-Alert-warningFilledColor: #fff;
// --palette-Alert-errorStandardBg: rgb(25, 17, 12);
// --palette-Alert-infoStandardBg: rgb(9, 24, 24);
// --palette-Alert-successStandardBg: rgb(11, 23, 13);
// --palette-Alert-warningStandardBg: rgb(25, 21, 10);
// --palette-Alert-errorIconColor: var(--palette-error-main, #FF5630);
// --palette-Alert-infoIconColor: var(--palette-info-main, #00B8D9);
// --palette-Alert-successIconColor: var(--palette-success-main, #22C55E);
// --palette-Alert-warningIconColor: var(--palette-warning-main, #FFAB00);
// --palette-AppBar-defaultBg: var(--palette-grey-900, #141A21);
// --palette-AppBar-darkBg: var(--palette-background-paper, #1C252E);
// --palette-AppBar-darkColor: var(--palette-text-primary, #FFFFFF);
// --palette-Avatar-defaultBg: var(--palette-grey-600, #637381);
// --palette-Button-inheritContainedBg: var(--palette-grey-800, #1C252E);
// --palette-Button-inheritContainedHoverBg: var(--palette-grey-700, #454F5B);
// --palette-Chip-defaultBorder: var(--palette-grey-700, #454F5B);
// --palette-Chip-defaultAvatarColor: var(--palette-grey-300, #DFE3E8);
// --palette-Chip-defaultIconColor: var(--palette-grey-300, #DFE3E8);
// --palette-FilledInput-bg: rgba(255, 255, 255, 0.09);
// --palette-FilledInput-hoverBg: rgba(255, 255, 255, 0.13);
// --palette-FilledInput-disabledBg: rgba(255, 255, 255, 0.12);
// --palette-LinearProgress-primaryBg: rgb(6, 52, 116);
// --palette-LinearProgress-secondaryBg: rgb(71, 25, 127);
// --palette-LinearProgress-errorBg: rgb(127, 43, 24);
// --palette-LinearProgress-infoBg: rgb(0, 92, 108);
// --palette-LinearProgress-successBg: rgb(17, 98, 47);
// --palette-LinearProgress-warningBg: rgb(127, 85, 0);
// --palette-Skeleton-bg: rgba(var(--palette-text-primaryChannel, 255 255 255) / 0.13);
// --palette-Slider-primaryTrack: rgb(6, 52, 116);
// --palette-Slider-secondaryTrack: rgb(71, 25, 127);
// --palette-Slider-errorTrack: rgb(127, 43, 24);
// --palette-Slider-infoTrack: rgb(0, 92, 108);
// --palette-Slider-successTrack: rgb(17, 98, 47);
// --palette-Slider-warningTrack: rgb(127, 85, 0);
// --palette-SnackbarContent-bg: rgb(250, 250, 250);
// --palette-SnackbarContent-color: rgba(0, 0, 0, 0.87);
// --palette-SpeedDialAction-fabHoverBg: rgb(62, 69, 77);
// --palette-StepConnector-border: var(--palette-grey-600, #637381);
// --palette-StepContent-border: var(--palette-grey-600, #637381);
// --palette-Switch-defaultColor: var(--palette-grey-300, #DFE3E8);
// --palette-Switch-defaultDisabledColor: var(--palette-grey-600, #637381);
// --palette-Switch-primaryDisabledColor: rgb(5, 46, 104);
// --palette-Switch-secondaryDisabledColor: rgb(63, 22, 114);
// --palette-Switch-errorDisabledColor: rgb(114, 38, 21);
// --palette-Switch-infoDisabledColor: rgb(0, 82, 97);
// --palette-Switch-successDisabledColor: rgb(15, 88, 42);
// --palette-Switch-warningDisabledColor: rgb(114, 76, 0);
// --palette-TableCell-border: rgba(46, NaN, NaN, 1);
// --palette-Tooltip-bg: rgba(69, 79, 91, 0.92);
// --opacity-inputPlaceholder: 0.5;
// --opacity-inputUnderline: 0.7;
// --opacity-switchTrackDisabled: 0.2;
// --opacity-switchTrack: 0.3;
