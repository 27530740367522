// ----------------------------------------------------------------------

export default function Divider(theme) {
  return {
    MuiDivider: {
      styleOverrides: {
        root: {
          borderWidth: 1.375,
          borderRadius: 2,
        },
      },
    },
  };
}
