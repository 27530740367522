// component
import SvgColor from '../../../components/svg-color';
import Iconify from '../../../components/iconify';

// import { Roles } from '../../../constants/roles';

// ----------------------------------------------------------------------

const navConfig = (user) => [
  {
    title: 'Memo Cards',
    path: '/student/memo',
    icon: <Iconify icon="material-symbols:cards" width={25} />,
  },
  {
    title: 'Subjects',
    path: '/student/subjects',
    icon: <Iconify icon="material-symbols:exercise-outline-sharp" width={25} />,
    hidden: user.type === 'memo',
  },
  {
    title: 'SAT',
    path: '/student/sat',
    icon: <Iconify icon="material-symbols:exercise-outline-sharp" width={25} />,
    hidden: user.type === 'memo',
  },
  {
    title: 'Video Lessons',
    path: '/student/lessons',
    icon: <Iconify icon="material-symbols:play-lesson-outline" width={25} />,
  },
  {
    title: 'Settings',
    path: '/student/settings',
    icon: <Iconify icon="eva:settings-outline" width={25} />,
  },
];

export default navConfig;
