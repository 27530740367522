// component
import SvgColor from '../../../components/svg-color';
import Iconify from '../../../components/iconify';

import { Roles } from '../../../constants/roles';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = (user) => [
  // {
  //   title: 'dashboard',
  //   path: '/app',
  //   icon: icon('ic_analytics'),
  // },

  // {
  //   title: 'user',
  //   path: '/user',
  //   icon: icon('ic_user'),
  //   hidden: user.role === Roles.student,
  // },

  // SAT section v2
  {
    title: 'Students',
    path: 'teacher/students',
    icon: <Iconify icon="eva:people-outline" width={25} />,
    section: 'sat',
  },
  {
    title: 'Units',
    path: 'teacher/units',
    icon: <Iconify icon="material-symbols:cards" width={25} />,
    section: 'sat',
  },
  {
    title: 'SAT Tests',
    path: 'teacher/sat-tests',
    icon: <Iconify icon="eva:cube-outline" width={25} />,
    section: 'sat',
  },
  // {
  //   title: 'Practice Tests',
  //   path: 'teacher/practice',
  //   icon: <Iconify icon="eva:folder-outline" width={25} />,
  //   section: 'sat',
  // },

  {
    title: 'questions',
    path: '/teacher/questions',
    icon: <Iconify icon="eva:file-text-outline" width={25} />,
    section: 'sat',
  },

  {
    title: 'Settings',
    path: '/teacher/settings',
    icon: <Iconify icon="eva:options-2-outline" width={25} />,
    section: 'sat',
  },

  // admin section
  {
    title: 'users',
    path: '/users',
    icon: icon('ic_user'),
    hidden: user.role !== Roles.admin,
    section: 'admin',
  },
  {
    title: 'user activities',
    path: '/users-activities',
    icon: <Iconify icon="eva:activity-outline" width={25} />,
    hidden: user.role !== Roles.admin,
    section: 'admin',
  },
];

export default navConfig;
