import PropTypes from 'prop-types';
import _ from 'lodash';
import Label from '../../../components/label';

export default function PlanLabel({ plan }) {
  return (
    <Label color={plan === 'paid' ? 'warning' : 'info'} variant="soft">
      {_.upperCase(plan === 'paid' ? 'Premium' : 'Free')}
    </Label>
  );
}

PlanLabel.propTypes = {
  plan: PropTypes.string,
};
