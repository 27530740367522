// ----------------------------------------------------------------------

export default function LinearProgress(theme) {
  return {
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          // backgroundColor: theme.palette.background.default,
          backgroundColor: theme.palette.action.disabledBackground,
          // padding: theme.spacing(3),
          // border: `2px solid ${theme.palette.grey[700]}`,
          height: 12,
          borderRadius: 6,
          width: '100%',
        },
      },
    },
  };
}
