import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box, Link, Typography } from '@mui/material';

// ----------------------------------------------------------------------

const Logo = forwardRef(({ disabledLink = false, sx, notext, ...other }, ref) => {
  const theme = useTheme();

  const PRIMARY_LIGHT = theme.palette.primary.light;

  const PRIMARY_MAIN = theme.palette.primary.main;

  const PRIMARY_DARK = theme.palette.primary.dark;

  // OR using local (public folder)
  // -------------------------------------------------------
  // const logo = (
  //   <Box
  //     component="img"
  //     src="/logo/logo_single.svg" => your path
  //     sx={{ width: 40, height: 40, cursor: 'pointer', ...sx }}
  //   />
  // );

  const logo = (
    <Box
      ref={ref}
      component="div"
      sx={{
        // width: 40,
        // height: 40,
        display: 'inline-flex',
        ...sx,
      }}
      {...other}
    >
      {/* <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 512 512">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>

          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>

          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>

        <g fill={PRIMARY_MAIN} fillRule="evenodd" stroke="none" strokeWidth="1">
          <path
            fill="url(#BG1)"
            d="M183.168 285.573l-2.918 5.298-2.973 5.363-2.846 5.095-2.274 4.043-2.186 3.857-2.506 4.383-1.6 2.774-2.294 3.939-1.099 1.869-1.416 2.388-1.025 1.713-1.317 2.18-.95 1.558-1.514 2.447-.866 1.38-.833 1.312-.802 1.246-.77 1.18-.739 1.111-.935 1.38-.664.956-.425.6-.41.572-.59.8-.376.497-.537.69-.171.214c-10.76 13.37-22.496 23.493-36.93 29.334-30.346 14.262-68.07 14.929-97.202-2.704l72.347-124.682 2.8-1.72c49.257-29.326 73.08 1.117 94.02 40.927z"
          />
          <path
            fill="url(#BG2)"
            d="M444.31 229.726c-46.27-80.956-94.1-157.228-149.043-45.344-7.516 14.384-12.995 42.337-25.267 42.337v-.142c-12.272 0-17.75-27.953-25.265-42.337C189.79 72.356 141.96 148.628 95.69 229.584c-3.483 6.106-6.828 11.932-9.69 16.996 106.038-67.127 97.11 135.667 184 137.278V384c86.891-1.611 77.962-204.405 184-137.28-2.86-5.062-6.206-10.888-9.69-16.994"
          />
          <path
            fill="url(#BG3)"
            d="M450 384c26.509 0 48-21.491 48-48s-21.491-48-48-48-48 21.491-48 48 21.491 48 48 48"
          />
        </g>
      </svg> */}
      {/* <svg width="192" height="59.42857142857142" viewBox="0 0 336 104" className="css-1j8o68f">
        <g
          id="SvgjsG1674"
          transform="matrix(2.0811657335344287,0,0,2.0811657335344287,-64.85536797642588,-51.79188898395862)"
          fill="#111111"
        >
          <switch xmlns="http://www.w3.org/2000/svg">
            <foreignObject
              requiredExtensions="http://ns.adobe.com/AdobeIllustrator/10.0/"
              x="0"
              y="0"
              width="1"
              height="1"
            ></foreignObject>
            <g>
              <g>
                <path
                  fill="#111111"
                  d="M60.251,69.538H40.514c-5.156,0-9.351-4.195-9.351-9.351V40.003c0-5.156,4.195-9.351,9.351-9.351h19.738     c5.156,0,9.351,4.195,9.351,9.351v20.184C69.603,65.343,65.408,69.538,60.251,69.538z M40.514,33.652     c-3.502,0-6.351,2.849-6.351,6.351v20.184c0,3.502,2.849,6.351,6.351,6.351h19.738c3.502,0,6.351-2.849,6.351-6.351V40.003     c0-3.502-2.849-6.351-6.351-6.351H40.514z"
                ></path>
              </g>
              <g>
                <path
                  fill="#111111"
                  d="M58.198,40.891c-0.829,0-1.5-0.671-1.5-1.5s0.671-1.547,1.5-1.547s1.5,0.624,1.5,1.453v0.095     C59.698,40.219,59.026,40.891,58.198,40.891z"
                ></path>
              </g>
              <g>
                <path
                  fill="#111111"
                  d="M58.198,48.891c-0.829,0-1.5-0.671-1.5-1.5s0.671-1.547,1.5-1.547s1.5,0.624,1.5,1.453v0.095     C59.698,48.219,59.026,48.891,58.198,48.891z"
                ></path>
              </g>
              <g>
                <path
                  fill="#111111"
                  d="M61.524,44.911h-6.654c-0.829,0-1.5-0.671-1.5-1.5s0.671-1.5,1.5-1.5h6.654c0.829,0,1.5,0.671,1.5,1.5     S62.353,44.911,61.524,44.911z"
                ></path>
              </g>
              <g>
                <path
                  fill="#111111"
                  d="M61.257,62.673c-0.384,0-0.768-0.146-1.061-0.439l-6.119-6.119c-0.586-0.585-0.586-1.536,0-2.121     c0.586-0.586,1.535-0.586,2.121,0l6.119,6.119c0.586,0.585,0.586,1.536,0,2.121C62.025,62.526,61.641,62.673,61.257,62.673z"
                ></path>
              </g>
              <g>
                <path
                  fill="#111111"
                  d="M55.138,62.673c-0.384,0-0.768-0.146-1.061-0.439c-0.586-0.585-0.586-1.536,0-2.121l6.119-6.119     c0.586-0.586,1.535-0.586,2.121,0c0.586,0.585,0.586,1.536,0,2.121l-6.119,6.119C55.906,62.526,55.522,62.673,55.138,62.673z"
                ></path>
              </g>
              <g>
                <path
                  fill="#111111"
                  d="M47.792,44.843h-8.654c-0.829,0-1.5-0.671-1.5-1.5s0.671-1.5,1.5-1.5h8.654c0.829,0,1.5,0.671,1.5,1.5     S48.621,44.843,47.792,44.843z"
                ></path>
              </g>
              <g>
                <path
                  fill="#111111"
                  d="M46.792,59.843h-6.654c-0.829,0-1.5-0.671-1.5-1.5s0.671-1.5,1.5-1.5h6.654c0.829,0,1.5,0.671,1.5,1.5     S47.621,59.843,46.792,59.843z"
                ></path>
              </g>
              <g>
                <path
                  fill="#111111"
                  d="M43.465,49.17c-0.829,0-1.5-0.671-1.5-1.5v-8.654c0-0.829,0.671-1.5,1.5-1.5s1.5,0.671,1.5,1.5v8.654     C44.965,48.499,44.293,49.17,43.465,49.17z"
                ></path>
              </g>
            </g>
          </switch>
        </g>
        {!notext && (
          <>
            <g id="SvgjsG1675" transform="matrix(1,0,0,1,335,0)" fill="#111111">
              <rect xmlns="http://www.w3.org/2000/svg" y="0" height="1" width="1" opacity="0"></rect>
              <rect xmlns="http://www.w3.org/2000/svg" y="0" x="-235" width="3" height="104"></rect>
            </g>
            <g
              id="SvgjsG1676"
              transform="matrix(3.432886932141475,0,0,3.432886932141475,117.99999836307195,7.599728859237938)"
              fill="#111111"
            >
              <path d="M7.64 9.98 c-1.68 0 -3.02 1.34 -3.02 3.02 c0 1.66 1.34 3 3.02 3 c1.1 0 2.02 -0.58 2.56 -1.44 c0.38 0.7 0.74 1.32 1.08 1.86 c0.52 0.94 1.12 1.9 1.18 2.02 l1.8 0 c-0.06 -0.08 -0.42 -0.68 -0.74 -1.24 c-0.2 -0.34 -0.42 -0.7 -0.64 -1.12 c0.54 -0.94 0.84 -1.96 0.84 -3.08 c0 -3.36 -2.72 -6.1 -6.08 -6.1 c-3.38 0 -6.1 2.74 -6.1 6.1 s2.72 6.08 6.1 6.08 c0.5 0 0.98 -0.06 1.46 -0.18 l0.8 1.38 c-0.74 0.22 -1.5 0.34 -2.26 0.34 c-4.22 0 -7.64 -3.4 -7.64 -7.62 s3.42 -7.64 7.64 -7.64 c4.2 0 7.62 3.42 7.62 7.64 c0 1.06 -0.2 2.06 -0.62 3 c0.58 1.02 1.12 2.02 1.58 2.76 c0.24 0.44 0.48 0.86 0.72 1.24 l-5.38 0 c-0.1 -0.2 -0.98 -1.7 -1.76 -3.02 c-0.66 0.38 -1.38 0.56 -2.16 0.56 c-2.5 0 -4.56 -2.04 -4.56 -4.54 s2.06 -4.56 4.56 -4.56 s4.54 2.06 4.54 4.56 c0 0.5 -0.08 0.98 -0.24 1.44 l-0.96 -1.64 c-0.5 -0.86 -1.02 -1.68 -1.1 -1.82 c-0.56 -0.62 -1.36 -1 -2.24 -1 z M32.620000000000005 5.98 l0 6.44 c0 4.16 -3.4 7.58 -7.6 7.58 c-4.12 0 -7.42 -3.24 -7.58 -7.32 l0 -6.7 l4.58 0 l0 6.44 c0 1.66 1.34 3 3 3 s2.94 -1.3 3 -2.92 l0 -6.52 l1.52 0 l0 6.44 c0 2.48 -2.02 4.52 -4.52 4.52 c-2.46 0 -4.5 -2 -4.5 -4.48 l-0.02 0 l0 -4.94 l-1.5 0 l0 5 c0.06 3.28 2.74 5.92 6.02 5.92 c3.34 0 6.02 -2.7 6.02 -6.02 l0 -6.44 l1.58 0 z  M40.1 20 l-4.68 0 l0 -14.02 l4.68 0 l0 10.9 l-1.56 0 l0 -9.34 l-1.56 0 l0 10.9 l3.12 0 l0 1.56 z M42.900000000000006 5.98 l15.36 0 l-6.44 9.34 l6.38 0 l0 4.68 l-15.3 0 l7.52 -10.92 l1.92 0 l-6.46 9.36 l10.76 0 l0 -1.54 l-7.8 -0.02 l6.44 -9.34 l-10.82 0 l0 1.56 l4.04 0 l-1.08 1.56 l-4.52 0 l0 -4.68 z"></path>
            </g>
          </>
        )}
      </svg> */}
    </Box>
  );

  const logo2 = <Box component="img" src="/assets/logo.png" sx={{ height: 40, width: 40, ...sx }} />;

  if (disabledLink) {
    return <>{logo}</>;
  }

  return (
    <Link to="/" component={RouterLink} sx={{ display: 'contents' }}>
      {logo2}
      {/* <Typography sx={{ fontWeight: 700 }}>Aceatee</Typography> */}
    </Link>
  );
});

Logo.propTypes = {
  sx: PropTypes.object,
  disabledLink: PropTypes.bool,
};

export default Logo;
