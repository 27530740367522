import { useState } from 'react';
import { useNavigate, Link as RouterLink } from 'react-router-dom';

import { Helmet } from 'react-helmet-async';
import { Field, Form } from 'react-final-form';

import { Box, Button, Card, Container, Divider, Grid, Link, Stack, Typography, styled } from '@mui/material';

import Header from '../../sections/main/Header';

import TextFieldControl from '../../components/TextFieldControl';
import Iconify from '../../components/iconify';

import { useAxios } from '../../services/axiosService';

import useResponsive from '../../hooks/useResponsive';

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledSection = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 480,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled('div')(({ theme }) => ({
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

export default function ContactPage() {
  const navigate = useNavigate();

  const mdUp = useResponsive('up', 'md');

  const { axios } = useAxios();

  const [sent, setSent] = useState(false);

  const onSubmit = async (values) => {
    try {
      await axios.post(`api/v2/public/contact`, values);
    } catch (error) {
      console.error(error);
    } finally {
      setSent(true);
    }
  };

  return (
    <>
      <Helmet>
        <title> Contact Us | Aceatee </title>
      </Helmet>

      <StyledRoot>
        {mdUp && (
          <StyledSection>
            <Box
              component="img"
              src="/assets/logo.png"
              sx={{ cursor: 'pointer', p: 5, width: 200 }}
              alt="Aceatee Logo"
            />
          </StyledSection>
        )}
        <Form
          onSubmit={onSubmit}
          render={({ handleSubmit }) => (
            <Container maxWidth="sm">
              <StyledContent>
                {!mdUp && (
                  <Box
                    component="img"
                    src="/assets/logo.png"
                    sx={{ cursor: 'pointer', mb: 10, alignSelf: 'center', width: 100 }}
                    alt="Aceatee Logo"
                  />
                )}
                {!sent && (
                  <>
                    <Typography variant="h4" gutterBottom>
                      Contact Us
                    </Typography>
                    <Typography variant="body1" sx={{ mb: 5, color: 'text.secondary' }}>
                      Want to request account or get more information? Please fill out the form below and we will get
                      back to you as soon as possible.
                    </Typography>
                  </>
                )}

                <Card sx={{ p: 5 }}>
                  {sent ? (
                    <Stack spacing={2} display={'flex'} flexDirection={'column'} alignItems={'center'}>
                      <Stack display={'flex'} flexDirection={'row'} alignItems={'center'}>
                        <Iconify
                          icon="eva:checkmark-circle-2-outline"
                          color="success.main"
                          width={40}
                          height={40}
                          sx={{ mr: 2 }}
                        />

                        <Typography variant="h6" gutterBottom>
                          Your message has been sent successfully. We will get back to you as soon as possible.
                        </Typography>
                      </Stack>
                      <Divider />
                      <Button
                        variant="text"
                        onClick={() => {
                          // navigate('/home');
                          window.location.href = 'https://aceatee.com';
                        }}
                      >
                        Back to Home
                      </Button>
                    </Stack>
                  ) : (
                    <form onSubmit={handleSubmit}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Field
                            name="name"
                            label={`Name`}
                            fullWidth
                            component={TextFieldControl}
                            autoComplete="off"
                            validate={(value) => {
                              if (!value) {
                                return 'We need to know your name';
                              }
                              return undefined;
                            }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Field
                            name="email"
                            label={`Email`}
                            type="email"
                            fullWidth
                            component={TextFieldControl}
                            autoComplete="off"
                            validate={(value) => {
                              if (!value) {
                                return 'We gonna reach out to you via email';
                              }
                              if (
                                !value?.match(
                                  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                                )
                              ) {
                                return 'Invalid email address';
                              }
                              return undefined;
                            }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Field
                            name="phone"
                            label={`Phone`}
                            fullWidth
                            component={TextFieldControl}
                            autoComplete="off"
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Field
                            name="message"
                            label={`Message`}
                            fullWidth
                            component={TextFieldControl}
                            autoComplete="off"
                            multiline
                            rows={4}
                            validate={(value) => {
                              if (!value) {
                                return 'We gonna reach out to you via email';
                              }
                              return undefined;
                            }}
                          />
                        </Grid>

                        <Grid container item justifyContent={'flex-end'}>
                          <Grid item>
                            <Button type="submit" variant="contained">
                              Send
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </form>
                  )}
                </Card>

                {!sent && (
                  <Typography variant="body2" sx={{ mt: 3, color: 'text.secondary' }}>
                    Still want to explore more?{' '}
                    <Link
                      variant="subtitle2"
                      component={RouterLink}
                      // to="/home"
                      to="https://aceatee.com"
                    >
                      Back to Home
                    </Link>
                  </Typography>
                )}
              </StyledContent>
            </Container>
          )}
        />
      </StyledRoot>
    </>
  );

  return (
    <>
      <Helmet>
        <title> Contact Us | Aceatee </title>
      </Helmet>

      <Header />

      <Container maxWidth="sm">
        <Form
          onSubmit={onSubmit}
          render={({ handleSubmit }) => (
            <Box>
              <Stack>
                <Typography variant="h4" gutterBottom>
                  Contact Us
                </Typography>
                <Typography variant="body1" sx={{ mb: 5, color: 'text.secondary' }}>
                  Want to request account or get more information? Please fill out the form below and we will get back
                  to you as soon as possible.
                </Typography>
              </Stack>
              <Card sx={{ p: 2 }}>
                {sent ? (
                  <Stack spacing={2} display={'flex'} flexDirection={'column'} alignItems={'center'}>
                    <Stack display={'flex'} flexDirection={'row'} alignItems={'center'}>
                      <Iconify
                        icon="eva:checkmark-circle-2-outline"
                        color="success.main"
                        width={40}
                        height={40}
                        sx={{ mr: 2 }}
                      />

                      <Typography variant="h6" gutterBottom>
                        Your message has been sent successfully. We will get back to you as soon as possible.
                      </Typography>
                    </Stack>
                    <Divider />
                    <Button
                      variant="text"
                      onClick={() => {
                        navigate('/home');
                      }}
                    >
                      Back to Home
                    </Button>
                  </Stack>
                ) : (
                  <form onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Field
                          name="name"
                          label={`Name`}
                          fullWidth
                          component={TextFieldControl}
                          autoComplete="off"
                          validate={(value) => {
                            if (!value) {
                              return 'We need to know your name';
                            }
                            return undefined;
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          name="email"
                          label={`Email`}
                          type="email"
                          fullWidth
                          component={TextFieldControl}
                          autoComplete="off"
                          validate={(value) => {
                            if (!value) {
                              return 'We gonna reach out to you via email';
                            }
                            if (
                              !value?.match(
                                /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                              )
                            ) {
                              return 'Invalid email address';
                            }
                            return undefined;
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Field name="phone" label={`Phone`} fullWidth component={TextFieldControl} autoComplete="off" />
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          name="message"
                          label={`Message`}
                          fullWidth
                          component={TextFieldControl}
                          autoComplete="off"
                          multiline
                          rows={4}
                          validate={(value) => {
                            if (!value) {
                              return 'We gonna reach out to you via email';
                            }
                            return undefined;
                          }}
                        />
                      </Grid>

                      <Grid container item justifyContent={'space-between'}>
                        <Grid item>
                          <Button onClick={() => navigate('/home')} variant="Text">
                            Back to Home
                          </Button>
                        </Grid>
                        <Grid item>
                          <Button type="submit" variant="contained">
                            Send
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </form>
                )}
              </Card>
            </Box>
          )}
        />
      </Container>
    </>
  );
}
