// src/HeroSection.js
import React from 'react';

import { Box, Divider, Grid, Stack, Typography, styled, useTheme } from '@mui/material';

import Iconify from '../../components/iconify';

import { bgGradient } from '../../utils/cssStyles';

const steps = [
  {
    title: 'Request an account',
    description: 'Get the credentials to access the platform and start your SAT preparation journey',
    img: '/assets/images/landing/contact-form.png',
    startColor: 'violet',
    endColor: 'secondary',
  },
  {
    title: 'Take SAT diagnostic test',
    description: 'Access your first free SAT test and receive a detailed report on your performance',
    img: '/assets/images/landing/start-sat-test.png',
    startColor: 'secondary',
    endColor: 'info',
  },
  {
    title: 'Improve you subject accuracy',
    description: 'Crafted practice tests to improve your accuracy',
    img: '/assets/images/landing/practice.png',
    startColor: 'error',
    endColor: 'info',
  },
];

const GridBox = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'grid',

  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: 'repeat(1, 1fr)',
    alignItems: 'start',
    justifyContent: 'center',
    gap: theme.spacing(3),
    marginTop: theme.spacing(3),
  },
  [theme.breakpoints.up('md')]: {
    gridTemplateColumns: 'repeat(2, 1fr)',
    alignItems: 'center',
    justifyContent: 'center',
    gap: theme.spacing(4),
    marginTop: theme.spacing(6),
  },
}));

const StyledStack = styled(Stack)(({ theme }) => ({
  width: '100%',
  height: '100%',
  display: 'grid',
  borderRadius: 'calc(12px + 16px)',

  [theme.breakpoints.down('lg')]: {
    gridTemplateColumns: 'repeat(1, 1fr)',
    padding: theme.spacing(5),
    alignItems: 'start',
  },
  [theme.breakpoints.up('lg')]: {
    gridTemplateColumns: 'repeat(2, 1fr)',
    padding: theme.spacing(4),
    gap: theme.spacing(5),
    alignItems: 'center',
  },
}));

const HowItWorks = () => {
  const theme = useTheme();
  return (
    <Grid
      item
      xs={11}
      sm={10}
      sx={{
        mt: 15,
        width: '100%',
      }}
    >
      <Typography variant="h3" component="h2" align="center" gutterBottom>
        A quick and easy way to start
      </Typography>
      <Typography variant="body1" align="center" color="textSecondary" paragraph>
        With Aceatee, we help students achieve the biggest jump in scores <br /> in the shortest time possible.
      </Typography>
      <GridBox>
        {steps.map((step, index) => (
          <StyledStack
            key={index}
            sx={{
              ...bgGradient({
                startColor: theme.palette[step.startColor].lighter,
                endColor: theme.palette[step.endColor].lighter,
              }),
            }}
          >
            <Box>
              <Typography variant="h3" component="h2" align="left" gutterBottom>
                {index + 1}. {step.title}
              </Typography>
              <Typography variant="body1" align="left" color="textSecondary" paragraph>
                {step.description}
              </Typography>
            </Box>
            <img src={step.img} alt={step.title} style={{ borderRadius: 12 }} />
          </StyledStack>
        ))}

        <StyledStack sx={{ gridTemplateColumns: { sm: 'repeat(1, 1fr)', md: 'repeat(1, 1fr)' } }}>
          <Box>
            <Typography variant="h3" component="h2" align="left" gutterBottom>
              4. Repeat
            </Typography>
            <Typography variant="body1" align="left" color="textSecondary" paragraph>
              In the last 4 years we’ve shown hundreds of students how to easily gain big jump in their math scores
              without hours of studying everyday. We’ve achieved impressive results with our students, and we can do the
              same for you too.
            </Typography>
            <Divider sx={{ borderStyle: 'dashed' }} />
            <Box
              sx={{
                mt: 3,
                display: 'flex',
                gap: 2,
              }}
            >
              <Stack sx={{ display: 'flex', flexDirection: 'row', alignItems: 'start' }}>
                <Iconify
                  icon="eva:checkmark-circle-2-outline"
                  sx={{
                    width: 30,
                    height: 30,
                    color: 'info.main',
                    mr: 1,
                  }}
                />
                <Box>
                  <Typography variant="subtitle1">Accuracy</Typography>
                  <Typography variant="body2" color="text.secondary">
                    Crafted practice tests to improve your accuracy
                  </Typography>
                </Box>
              </Stack>
              <Stack sx={{ display: 'flex', flexDirection: 'row' }}>
                <Iconify
                  icon="eva:clock-outline"
                  sx={{
                    width: 30,
                    height: 30,
                    color: 'success.main',
                    mr: 1,
                  }}
                />
                <Box>
                  <Typography variant="subtitle1">Time</Typography>
                  <Typography variant="body2" color="text.secondary">
                    Spending all day on studying is no longer a case
                  </Typography>
                </Box>
              </Stack>
            </Box>
          </Box>
        </StyledStack>
      </GridBox>
    </Grid>
  );
};

export default HowItWorks;
