import { alpha } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function Dialog(theme) {
  return {
    MuiBackdrop: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
        },
      },
    },
  };
}
