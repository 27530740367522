import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

import StudentPortalLayout from '../layouts/StudentPortal';

// v2
// import StudentDashboardPage from '../pages/StudentPortal/v2/DashboardPage';
// import InstructionsPage from '../pages/StudentPortal/v2/Instructions';

const MemoPage = lazy(() => import('../pages/StudentPortal/v2/Memo/ListPage'));
const MemoSessionPage = lazy(() => import('../pages/StudentPortal/v2/Memo/SessionPage'));

const SubjectsPage = lazy(() => import('../pages/StudentPortal/v2/Subjects/ListPage'));
const SubjectsLevelPage = lazy(() => import('../pages/StudentPortal/v2/Subjects/LevelPage'));
const SubjectsSessionPage = lazy(() => import('../pages/StudentPortal/v2/Subjects/SessionPage'));
const SubjectsResultPage = lazy(() => import('../pages/StudentPortal/v2/Subjects/ResultPage'));

const SATPage = lazy(() => import('../pages/StudentPortal/v2/SAT/SATPage'));
const SATSessionPage = lazy(() => import('../pages/StudentPortal/v2/SAT/SessionPage'));
const SATResultPage = lazy(() => import('../pages/StudentPortal/v2/SAT/ResultPage'));

const LessonsPage = lazy(() => import('../pages/StudentPortal/v2/VideoLessons'));
const SettingsPage = lazy(() => import('../pages/StudentPortal/v2/SettingsPage'));

// const PracticePage = lazy(() => import('../pages/StudentPortal/v2/Practice/PracticePage'));
// const SessionInstructionsPage = lazy(() => import('../pages/StudentPortal/v2/Practice/Session/Instructions'));

// const StudentPortalSATResultPage = lazy(() => import('../pages/StudentPortal/v2/Practice/SAT/SATResultPage'));

// const SubjectResultPage = lazy(() => import('../pages/StudentPortal/v2/Practice/Session/SubjectResult'));
// const SATResultPage = lazy(() => import('../pages/StudentPortal/v2/Practice/Session/SATResult'));

// const PaymentPage = lazy(() => import('../pages/StudentPortal/v2/Billing'));
// const PaymentComplete = lazy(() => import('../pages/StudentPortal/v2/Billing/PaymentComplete'));

export default function Routes(user) {
  const memoStudent = user?.type === 'memo';

  const memoRoutes = [
    {
      path: 'student/memo',
      element: <MemoPage />,
    },
    {
      path: 'student/memo/session/:unitId',
      element: <MemoSessionPage />,
    },
    {
      path: 'student/settings',
      element: <SettingsPage />,
    },
  ];

  const subjectRoutes = [
    {
      path: 'student/subjects',
      element: <SubjectsPage />,
    },
    {
      path: 'student/subjects/level/:subject',
      element: <SubjectsLevelPage />,
    },
    {
      path: 'student/subjects/session/:subject',
      element: <SubjectsSessionPage />,
    },
    {
      path: 'student/subjects/result/:id',
      element: <SubjectsResultPage />,
    },
  ];
  const satRoutes = [
    {
      path: 'student/sat',
      element: <SATPage />,
    },
    {
      path: 'student/sat/session',
      element: <SATSessionPage />,
    },
    {
      path: 'student/sat/result/:id',
      element: <SATResultPage />,
    },
  ];

  const routes = [
    {
      path: '/',
      element: <StudentPortalLayout />,
      children: memoStudent
        ? [{ element: <Navigate to={'/student/memo'} />, index: true }, ...memoRoutes]
        : [
            // v2
            { element: <Navigate to={'/student/memo'} />, index: true },
            ...memoRoutes,
            ...subjectRoutes,
            ...satRoutes,

            {
              path: 'student/lessons',
              element: <LessonsPage />,
            },
            {
              path: 'student/settings',
              element: <SettingsPage />,
            },
            // {
            //   path: 'student/practice/sat/instructions',
            //   element: <SessionInstructionsPage sat />,
            // },
            // {
            //   path: 'student/practice/sat/session/:data',
            //   element: <SATSessionPage />,
            // },
            // {
            //   path: 'student/practice/sat/result/:id',
            //   element: <SATResultPage />,
            // },
            // {
            //   path: 'student/practice/subjects',
            //   element: <StudentPortalSubjectsPage />,
            // },
            // {
            //   path: 'student/practice/subject/level/:subject',
            //   element: <LevelPage />,
            // },
            // {
            //   path: 'student/practice/subject/instructions/:subject',
            //   element: <SessionInstructionsPage />,
            // },
            // {
            //   path: 'student/practice/subject/session/:subject',
            //   element: <SubjectSessionPage />,
            // },
            // {
            //   path: 'student/practice/subject/result/:id',
            //   element: <SubjectResultPage />,
            // },

            // {
            //   path: 'student/practice',
            //   element: <PracticePage />,
            // },
            // {
            //   path: 'student/dashboard',
            //   element: <StudentDashboardPage />,
            // },

            // {
            //   path: 'student/instructions',
            //   element: <InstructionsPage />,
            // },
          ],
    },
  ];

  return routes;
}
