import { useContext, useEffect } from 'react';
import { useNavigate, Link as RouterLink } from 'react-router-dom';

import { Helmet } from 'react-helmet-async';
// @mui
import { styled } from '@mui/material/styles';
import { Container, Typography, Stack, Link, Box } from '@mui/material';

import { AppContext } from '../../App';

// hooks
import useResponsive from '../../hooks/useResponsive';
// sections
import { LoginForm } from '../../sections/auth/login';

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledSection = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 480,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 420,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function LoginPage() {
  const navigate = useNavigate();
  const { user } = useContext(AppContext);
  const mdUp = useResponsive('up', 'md');

  useEffect(() => {
    if (user) {
      navigate('/', { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <>
      <Helmet>
        <title> Sign In | Aceatee </title>
      </Helmet>

      <StyledRoot>
        {mdUp && (
          <StyledSection>
            <Box
              component="img"
              src="/assets/logo.png"
              sx={{ cursor: 'pointer', p: 5, width: 200 }}
              alt="Aceatee Logo"
            />
          </StyledSection>
        )}

        <Container maxWidth="sm">
          <StyledContent>
            {!mdUp && (
              <Box
                component="img"
                src="/assets/logo.png"
                sx={{ cursor: 'pointer', mb: 10, maxWidth: 100, alignSelf: 'center' }}
                alt="Aceatee Logo"
              />
            )}
            <Typography variant="h5" gutterBottom>
              Sign in to
              <Typography variant="h5" component="span" sx={{ color: 'primary.main', fontWeight: 'bold' }}>
                {' '}
                Aceatee
              </Typography>
            </Typography>

            <Typography variant="body2" sx={{ mb: 5, color: 'text.secondary' }}>
              Don’t have an account? {''}
              <Link variant="subtitle2" component={RouterLink} to="/contact">
                Request credentials here
              </Link>
            </Typography>

            <Stack direction="row" spacing={2} alignItems={'center'}></Stack>

            <LoginForm />
            <Typography variant="body2" sx={{ mt: 3, color: 'text.secondary' }}>
              Still want to explore more?{' '}
              <Link
                variant="subtitle2"
                component={RouterLink}
                // to="/home"
                to="https://aceatee.com"
              >
                Back to Home
              </Link>
            </Typography>
          </StyledContent>
        </Container>
      </StyledRoot>
    </>
  );
}
